import { UseToastOptions } from '@chakra-ui/react';

const ServiceSuccessMessage = (success: string, manuallyClose?: boolean): UseToastOptions => ({
    position: 'bottom',
    title: 'Service Success',
    description: success,
    status: 'success',
    duration: manuallyClose ? undefined : 5000,
    isClosable: true
});

export default ServiceSuccessMessage;
