import { Box, FormHelperText, FormLabel, Select, SelectProps } from '@chakra-ui/react';
import React from 'react';

export interface DropdownProps extends SelectProps {
    options: { id: string | number | readonly string[]; label: string | number | readonly string[] }[];
    helperText?: string;
    allowEmptySelection?: boolean;
    headerTitle?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ headerTitle, options, allowEmptySelection = false, helperText, ...props }) => (
    <Box>
        {headerTitle && <FormLabel my={2}>{headerTitle}</FormLabel>}
        <Select {...props}>
            {allowEmptySelection && <option value={''}>None</option>}
            {options.map((option) => (
                <option key={option.id.toString()} value={option.id}>
                    {option.label}
                </option>
            ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
);

export default Dropdown;
