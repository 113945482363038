import { gql } from '@apollo/client';

export class CourseGetQueries {
    static getCourses = gql`
        query get_courses($searchArgs: GetCoursesArgs!) {
            getCourses(searchArgs: $searchArgs) {
                finalPage
                limit
                page
                total
                courses {
                    id
                    createdOn
                    updatedOn
                    yearGroups {
                        yearLevel
                        id
                    }
                    courseItems {
                        id
                        isPrimary
                        localeId
                        title
                        internalNotes
                    }
                    countries {
                        countryCode
                    }
                    stateCode
                    linkedContentItems: contentItemsInCourse {
                        id
                        contentItem {
                            id
                            contentMedia {
                                id
                                isPrimary
                                localeId
                                title
                                content
                            }
                        }
                    }
                    linkedUnitsOfWork: unitsOfWorkInCourse {
                        id
                        sortOrder
                        strand {
                            id
                            title
                        }
                        unitOfWork {
                            id
                            updatedOn
                            unitOfWorkItems {
                                id
                                localeId
                                title
                                teacherTitle
                                isPrimary
                                internalNotes
                            }
                            contentItemsInUnitOfWork {
                                id
                            }
                            questionSetsInUnitOfWork {
                                id
                            }
                        }
                    }
                }
            }
        }
    `;
}
