import { Tooltip, useDisclosure } from '@chakra-ui/react';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface FaIconButtonProps {
    tooltipLabel?: string
    icon: IconProp
    size?: SizeProp
    cursor?: string
    color?: string
    hoverColor?: string
    onClick: () => void
}

const FaIconButton: React.FC<FaIconButtonProps> = ({ icon, size = 'lg', color = 'auto',  tooltipLabel, hoverColor = "#319795", cursor, onClick }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Tooltip label={tooltipLabel}>
            <FontAwesomeIcon
                icon={icon}
                size={size}
                cursor={cursor}
                color={color}
                aria-label={tooltipLabel}
                style={isOpen ? { color: hoverColor } : {}}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                onClick={onClick}
            />
        </Tooltip>
    );
};

export default FaIconButton;