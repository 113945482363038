import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faEye, faQuestion } from '@fortawesome/free-solid-svg-icons';
import {
    faBarChart,
    faBong,
    faBook,
    faBox,
    faCat,
    faComment,
    faDna,
    faGlobeAsia,
    faHouseHeart,
    faImages,
    faKey,
    faLandmark,
    faPaperclip,
    faSchool,
    faSuitcase
} from '@fortawesome/pro-solid-svg-icons';

import { useFeatureFlag } from '../../../feature-flags/use-feature-flag';

export const useNavigationAuthoringItems = () => {
    const isTagCreationEnabled = useFeatureFlag('learnosity-statistics-enabled');

    return [
        { title: 'Content Authoring' },
        { title: `Build number: ${window._env_.BUILDKITE_BUILD_NUMBER}` },
        { title: `Environment: ${window._env_.ENVIRONMENT.toUpperCase()}` },
        { title: 'Home', icon: faHouseHeart, routeTo: '/' },
        { title: 'Questions', icon: faQuestion, routeTo: '/questions' },
        { title: 'Question Sets', icon: faComment, routeTo: '/question-sets' },
        { title: 'Units of Work', icon: faSuitcase, routeTo: '/units-of-work' },
        { title: 'Courses', icon: faSchool, routeTo: '/courses' },
        { title: 'Content Items', icon: faBox, routeTo: '/content-items' },
        { title: 'Strand', icon: faDna, routeTo: '/strand' },
        { title: 'Outcome code', icon: faBong, routeTo: '/outcome-code' },
        { title: 'CAT Questions', icon: faCat, routeTo: '/cat-questions' },
        { title: 'Reports' },
        ...(isTagCreationEnabled ? [{ title: 'CAT Statistics', icon: faBarChart, routeTo: '/cat-statistics' }] : []),
        { title: 'CAT Utility', icon: faPaperclip, routeTo: '/cat-utility' },
        { title: 'CAT Snapshots', icon: faImages, routeTo: '/cat-snapshots' },
        { title: 'Content Validator', icon: faEye, routeTo: '/content-validator' },
        { title: 'Course Manager' },
        { title: 'Default Course', icon: faFile, routeTo: '/default-course' },
        { title: 'Settings' },
        { title: 'Countries', icon: faLandmark, routeTo: '/settings/countries' },
        { title: 'Locales', icon: faGlobeAsia, routeTo: '/settings/locales' },
        { title: 'Login Credential', icon: faKey, routeTo: '/settings/login-credential' },
        { title: 'Data Dictionary', icon: faBook, routeTo: '/settings/data-dictionary' }
    ];
};
