import { action, makeAutoObservable, observable } from 'mobx';

import { AnswerObjProps, AnswersProps, validationOptionsProps } from './answers-store.types';
import { findAnswerIndex } from './answers-store.utils';

class AnswersStore {
    answers: AnswersProps = [[]];
    answerIndex = 0;
    removedAnswers: { [id: number]: AnswerObjProps } = {};

    constructor() {
        makeAutoObservable(this, {
            answers: observable,
            answerIndex: observable,
            removedAnswers: observable,
            initAnswer: action,
            setAnswer: action,
            getAnswer: action,
            getAnswers: action,
            dumpAnswers: action,
            removeAnswer: action,
            clearAnswers: action,
            addSet: action,
            removeSet: action,
            setAnswerIndex: action,
            setValidationMethod: action,
            getOptions: action,
            setOptions: action
        });
    }

    initAnswer(id: number, presets?: AnswerObjProps) {
        this.answers.forEach((answerIndex) => {
            presets
                ? answerIndex?.push(presets)
                : answerIndex?.push({
                      id,
                      validationMethod: 'string',
                      options: {
                          threshold: 0,
                          ignoreOrder: false,
                          simplify: false,
                          ignoreNestedOrder: false,
                          disregardTrailingZeros: false,
                          disregardCoefficientOne: false,
                          ignoreLatexSpace: false,
                          unmarked: false
                      }
                  });
        });
    }

    addSet() {
        this.answers.push(
            this.answers[this.answerIndex]?.map(({ id, options, validationMethod }) => {
                return { id, validationMethod, options };
            })
        );
    }

    setAnswer(answer: Pick<AnswerObjProps, 'id' | 'content'>) {
        const { id, content } = answer;
        const index = findAnswerIndex(id, this.answerIndex, this.answers);

        if (index !== null) {
            this.answers[this.answerIndex][index].content = content;
        }
    }

    getAnswer(id: number) {
        return this.answers[this.answerIndex]?.find((answer) => answer.id === id)?.content;
    }

    getAnswers() {
        return this.answers;
    }

    dumpAnswers(answers: AnswersProps) {
        this.answers = answers;
    }

    removeAnswer(id: number) {
        const answer = this.answers[this.answerIndex]?.find((answer) => answer.id === id);

        if (answer) {
            this.removedAnswers[id] = answer;
        }

        this.answers.forEach((answerIndex) => {
            answerIndex?.find((answer, index) => {
                if (id === answer?.id) {
                    answerIndex.splice(index, 1);
                }
            });
        });
    }

    clearAnswers() {
        this.answers = [[]];
    }

    clearRemovedAnswers() {
        this.removedAnswers = {};
    }

    resetAnswerIndex() {
        this.answerIndex = 0;
    }

    removeSet(index: number) {
        this.answers.splice(index, 1);
        this.answerIndex = 0;
    }

    setAnswerIndex(index: number) {
        this.answerIndex = index;
    }

    getValidationMethod(id: number) {
        return this.answers[this.answerIndex]?.find((answer) => answer.id === id)?.validationMethod;
    }

    setValidationMethod(id: number, method: string) {
        const index = findAnswerIndex(id, this.answerIndex, this.answers);

        if (index !== null) {
            this.answers[this.answerIndex][index].validationMethod = method;
        }
    }

    getOptions(id: number) {
        return this.answers[this.answerIndex]?.find((answer) => answer.id === id)?.options;
    }

    getMetaData(id: number) {
        return this.answers[this.answerIndex]?.find((answer) => answer.id === id)?.metaData;
    }

    setOptions(id: number, options: validationOptionsProps) {
        const index = findAnswerIndex(id, this.answerIndex, this.answers);

        if (index !== null) {
            this.answers[this.answerIndex][index].options = options;
        }
    }

    setMetaData(metaData: Pick<AnswerObjProps, 'id' | 'content'>) {
        const { id, content } = metaData;
        const index = findAnswerIndex(id, this.answerIndex, this.answers);

        if (index !== null) {
            this.answers[this.answerIndex][index].metaData = content;
        }
    }

    removeMetaData(id: number) {
        const index = findAnswerIndex(id, this.answerIndex, this.answers);

        if (index !== null) {
            const answerObj = this.answers[this.answerIndex][index];

            delete answerObj.metaData;
        }
    }
}

export default AnswersStore;
